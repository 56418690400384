import { gql, useApolloClient, useQuery } from '@apollo/client';
import { ErrorMessage } from 'formik';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';

import DocumentListItem from '../components/document-list-item';
import ProjectListItem from '../pages/home/project-list-item';

export const styles = (theme) => ({
  list: {
    padding: 0,
    maxHeight: 500,
    overflowY: 'auto',
  },
  button: {
    marginTop: 5,
  },
  root: {
    width: '100%',
    marginBottom: 20,
    marginTop: 100,
    maxWidth: 650,
    [theme.breakpoints.down('xs')]: {
      marginTop: 110,
    },
  },
  grow: {
    flexGrow: 1,
  },
  widget: {
    marginBottom: theme.spacing(2),
  },
  loadMoreButton: {
    width: '100%',
    textAlign: 'center',
    marginTop: theme.spacing(1),
  },
  labelContainer: {
    padding: '6px',
  },
  direction: {
    zIndex: 1000,
    position: 'absolute !Important',
    [theme.breakpoints.down('sm')]: {
      top: 0,
      right: 0,
      //left: 'auto',
      marginRight: 0,
      zIndex: 1,
    },
    [theme.breakpoints.up('md')]: {
      top: '1% !Important',
      right: '0% !Important',
      marginRight: 15,
    },
  },
});

export const SEARCH = gql`
  query Search($term: String!) {
    search(term: $term) {
      id
      name
      referenceNumber
      isFavourite
      county
      startDate
      endDate
      customer {
        id
        name
      }
    }

    searchDocument(searchTerm: $term) {
      id
      filename
      mime
      path
      tags
      modifiedAt
      modifiedBy
      projectReference
    }
  }
`;

const SEARCH_PROJECTS = gql`
  query Search($term: String!, $dormant: Boolean, $take: Int) {
    search(term: $term, dormant: $dormant, take: $take) {
      id
      name
      referenceNumber
      isFavourite
      county
      startDate
      endDate
      customer {
        id
        name
      }
    }
  }
`;

const SEARCH_DOCUMENTS = gql`
  query SearchDocuments($term: String!, $take: Int) {
    searchDocument(searchTerm: $term, take: $take) {
      id
      filename
      mime
      path
      tags
      modifiedAt
      modifiedBy
      projectReference
    }
  }
`;

const SEARCH_TERM = gql`
  query SearchTerm {
    projectSearch @client {
      term
    }
  }
`;

export function closeSearchResults(client) {
  const data = {
    projectSearch: {
      term: null,
      __typename: 'ProjectSearch',
    },
  };
  client.term = null;
  client.writeQuery({
    query: SEARCH_TERM,
    data: data,
  });
}
const DEFAULT_SEARCH_COUNT = 25;

const PROJECTS = 0;
const DOCUMENTS = 1;
const INACTIVE_PROJECTS = 2;

const ResultList = ({
  classes,
  activeProjects,
  activeProjectsLoading,
  inactiveProjects,
  inactiveProjectsLoading,
  documents,
  documentsLoading,
  takeActiveProjects,
  setTakeActiveProjects,
  takeInactiveProjects,
  setTakeInactiveProjects,
  takeDocuments,
  setTakeDocuments,
}) => {
  const client = useApolloClient();

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const selectedItemRef = useRef(null);

  const maxItems = useMemo(() => {
    if (
      !activeProjectsLoading ||
      !documentsLoading ||
      !inactiveProjectsLoading
    ) {
      if (tabValue === PROJECTS) {
        return activeProjects?.search?.length ?? 0;
      } else if (tabValue === DOCUMENTS) {
        return documents?.searchDocument?.length ?? 0;
      } else if (tabValue === INACTIVE_PROJECTS) {
        return inactiveProjects?.search?.length ?? 0;
      }
    }
    return 0;
  }, [
    activeProjects?.search,
    activeProjectsLoading,
    documents?.searchDocument,
    documentsLoading,
    inactiveProjects?.search,
    inactiveProjectsLoading,
    tabValue,
  ]);

  const handleChange = useCallback((event, value) => {
    setSelectedItemIndex(0);
    setTabValue(value);
  }, []);

  useEffect(() => {
    const handleKeyPress = (a) => {
      if (a.key === 'ArrowDown') {
        if (selectedItemIndex + 1 === maxItems) {
          setSelectedItemIndex(0);
        } else {
          setSelectedItemIndex(selectedItemIndex + 1);
        }
      }

      if (a.key === 'ArrowUp') {
        if (selectedItemIndex === 0) {
          setSelectedItemIndex(maxItems - 1);
        } else {
          setSelectedItemIndex(selectedItemIndex - 1);
        }
      }

      if (a.key === 'ArrowRight') {
        setSelectedItemIndex(0);
        setTabValue(tabValue === 2 ? 2 : tabValue + 1);
      }

      if (a.key === 'ArrowLeft') {
        setSelectedItemIndex(0);
        setTabValue(tabValue === 0 ? 0 : tabValue - 1);
      }

      if (a.key === 'Enter') {
        document.activeElement.blur();
        if (selectedItemRef.current) {
          selectedItemRef.current.click();
        }
      }

      if (a.key === 'Escape') {
        closeSearchResults(client);
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [client, maxItems, selectedItemIndex, tabValue]);

  return (
    <Paper>
      <Tabs
        value={tabValue}
        indicatorColor='secondary'
        textColor='primary'
        onChange={handleChange}
        variant='scrollable'
      >
        <Tab
          classes={{ labelContainer: classes.labelContainer }}
          label={
            <>
              Projekt
              {activeProjectsLoading ? (
                <CircularProgress
                  style={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '10px',
                  }}
                />
              ) : activeProjects.search ? (
                `(${activeProjects.search.length})`
              ) : (
                `(0)`
              )}
            </>
          }
        >
          <CircularProgress />
        </Tab>
        <Tab
          classes={{ labelContainer: classes.labelContainer }}
          label={
            <>
              Dokument
              {documentsLoading ? (
                <CircularProgress
                  style={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '10px',
                  }}
                />
              ) : documents.searchDocument ? (
                `(${documents.searchDocument.length})`
              ) : (
                `(0)`
              )}
            </>
          }
        />
        <Tab
          classes={{ labelContainer: classes.labelContainer }}
          label={
            <>
              Avslutade projekt
              {inactiveProjectsLoading ? (
                <CircularProgress
                  style={{
                    width: '15px',
                    height: '15px',
                    marginLeft: '10px',
                  }}
                />
              ) : inactiveProjects.search ? (
                `(${inactiveProjects.search.length})`
              ) : (
                `(0)`
              )}
            </>
          }
        />
        <div className={classes.grow} />
        <IconButton
          style={{ margin: 4 }}
          onClick={() => closeSearchResults(client)}
        >
          <ClearIcon />
        </IconButton>
      </Tabs>
      <Divider />
      {tabValue === PROJECTS && (
        <TabContainer>
          <List className={classes.list} dense>
            {activeProjects?.search?.map((p, i) => {
              const isSelected = i === selectedItemIndex;

              return (
                <ProjectListItem
                  selected={isSelected}
                  project={p}
                  key={p.id}
                  clickCallback={() => closeSearchResults(client)}
                  ref={isSelected ? selectedItemRef : null}
                />
              );
            })}
            {!activeProjectsLoading &&
              (activeProjects?.search?.length > 0 ? (
                <Button
                  variant='text'
                  className={classes.loadMoreButton}
                  onClick={() =>
                    setTakeActiveProjects((x) => x + DEFAULT_SEARCH_COUNT)
                  }
                  disabled={activeProjects.search.length < takeActiveProjects}
                >
                  Ladda fler
                </Button>
              ) : (
                <ListItem>
                  <ListItemText primary='Inga aktiva projekt matchade sökningen' />
                </ListItem>
              ))}
          </List>
        </TabContainer>
      )}
      {tabValue === DOCUMENTS && (
        <TabContainer>
          <List className={classes.list} dense>
            {documents?.searchDocument?.map((doc, i) => {
              const isSelected = i === selectedItemIndex;

              return (
                <DocumentListItem
                  projectReferenceNumber
                  goToProjectOnClick
                  selected={isSelected}
                  key={doc.id}
                  doc={doc}
                  showPath
                  ref={isSelected ? selectedItemRef : null}
                />
              );
            })}
            {!documentsLoading &&
              (documents?.searchDocument?.length > 0 ? (
                <Button
                  variant='text'
                  className={classes.loadMoreButton}
                  onClick={() =>
                    setTakeDocuments((x) => x + DEFAULT_SEARCH_COUNT)
                  }
                >
                  Ladda fler
                </Button>
              ) : (
                <ListItem>
                  <ListItemText primary='Inga dokument matchade sökningen' />
                </ListItem>
              ))}
          </List>
        </TabContainer>
      )}
      {tabValue === INACTIVE_PROJECTS && (
        <TabContainer>
          <List className={classes.list} dense>
            {inactiveProjects?.search?.map((p, i) => {
              const isSelected = i === selectedItemIndex;

              return (
                <ProjectListItem
                  selected={isSelected}
                  project={p}
                  key={p.id}
                  clickCallback={() => closeSearchResults(client)}
                  ref={isSelected ? selectedItemRef : null}
                />
              );
            })}
            {!inactiveProjectsLoading &&
              (inactiveProjects?.search?.length > 0 ? (
                <Button
                  variant='text'
                  className={classes.loadMoreButton}
                  onClick={() =>
                    setTakeInactiveProjects((x) => x + DEFAULT_SEARCH_COUNT)
                  }
                  disabled={
                    inactiveProjects.search.length < takeInactiveProjects
                  }
                >
                  Ladda fler
                </Button>
              ) : (
                <ListItem>
                  <ListItemText primary='Inga avslutade projekt matchade sökningen' />
                </ListItem>
              ))}
          </List>
        </TabContainer>
      )}
    </Paper>
  );
};

function SearchResults(props) {
  const [headerEl, setHeaderEl] = useState(null);
  const [takeActiveProjects, setTakeActiveProjects] =
    useState(DEFAULT_SEARCH_COUNT);
  const [takeInactiveProjects, setTakeInactiveProjects] =
    useState(DEFAULT_SEARCH_COUNT);
  const [takeDocuments, setTakeDocuments] = useState(DEFAULT_SEARCH_COUNT);
  const searchResultsRef = useRef(null);

  const { data: searchData, loading: searchLoading } = useQuery(SEARCH_TERM);

  const searchTerm = useMemo(
    () => searchData?.projectSearch?.term ?? '',
    [searchData?.projectSearch?.term]
  );

  const {
    data: activeProjectsData,
    loading: activeProjectsLoading,
    error: activeProjectsError,
    refetch: activeProjectsRefetch,
  } = useQuery(SEARCH_PROJECTS, {
    variables: {
      term: searchTerm,
      dormant: false,
      take: takeActiveProjects,
    },
  });

  const {
    data: inactiveProjectsData,
    loading: inactiveProjectsLoading,
    error: inactiveProjectsError,
    refetch: inactiveProjectsRefetch,
  } = useQuery(SEARCH_PROJECTS, {
    variables: {
      term: searchTerm,
      dormant: true,
      take: takeInactiveProjects,
    },
  });

  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError,
    refetch: documentsRefetch,
  } = useQuery(SEARCH_DOCUMENTS, {
    variables: {
      term: searchTerm,
      take: takeDocuments,
    },
  });

  useEffect(() => {
    setHeaderEl(document.getElementById('header'));
    searchResultsRef.current.focus();
  }, []);

  useEffect(() => {
    activeProjectsRefetch({
      term: searchTerm,
      dormant: false,
      take: takeActiveProjects,
    });
  }, [takeActiveProjects, activeProjectsRefetch, searchTerm]);

  useEffect(() => {
    inactiveProjectsRefetch({
      term: searchTerm,
      dormant: true,
      take: takeInactiveProjects,
    });
  }, [takeInactiveProjects, inactiveProjectsRefetch, searchTerm]);

  useEffect(() => {
    documentsRefetch({
      term: searchTerm,
      take: takeDocuments,
    });
  }, [takeDocuments, documentsRefetch, searchTerm]);

  const { classes } = props;

  return (
    <div ref={searchResultsRef} style={{ right: 0 }}>
      {!searchLoading && searchTerm && (
        <Popper
          anchorEl={headerEl}
          open
          className={classes.direction}
          style={{ left: 'auto' }}
        >
          <Paper className={classes.root} square elevation={24}>
            {!activeProjectsError &&
            !inactiveProjectsError &&
            !documentsError ? (
              <ResultList
                classes={classes}
                activeProjects={activeProjectsData}
                activeProjectsLoading={activeProjectsLoading}
                inactiveProjects={inactiveProjectsData}
                inactiveProjectsLoading={inactiveProjectsLoading}
                documents={documentsData}
                documentsLoading={documentsLoading}
                setTakeActiveProjects={setTakeActiveProjects}
                takeActiveProjects={takeActiveProjects}
                setTakeInactiveProjects={setTakeInactiveProjects}
                takeInactiveProjects={takeInactiveProjects}
                takeDocuments={takeDocuments}
                setTakeDocuments={setTakeDocuments}
              />
            ) : (
              <ErrorMessage
                error={
                  activeProjectsError || inactiveProjectsError || documentsError
                }
                gutter
              />
            )}
          </Paper>
        </Popper>
      )}
    </div>
  );
}

function TabContainer(props) {
  return <Typography component='div'>{props.children}</Typography>;
}

export default withStyles(styles)(SearchResults);
