import { Icon } from '@material-ui/core';

export function getIcon({ filename, mime }) {
  if (filename === 'Bilder') {
    return 'photo_library';
  }
  if (/image/.test(mime)) {
    return 'photo-library';
  }
  if (/(javascript|json|html|css)/.test(mime)) {
    return 'code';
  }
  if (/video/.test(mime)) {
    return 'videocam';
  }
  if (/audio/.test(mime)) {
    return 'audiotrack';
  }
  if (/(excel|spreadsheet)/.test(mime) || /\.xls/.test(filename)) {
    return 'table_chart';
  }
  if (/word/.test(mime) || /\.doc/.test(filename)) {
    return 'notes';
  }
  if (
    /(presentation|presentation|publish)/.test(mime) ||
    /\.ppt/.test(filename)
  ) {
    return 'present_to_all';
  }
  if (/pdf/.test(mime)) {
    return 'picture_as_pdf';
  }
  if (
    /(compress|tar|rar|zip)/.test(mime) ||
    /\.(zip|tar|rar|7zip)/.test(filename)
  ) {
    return 'archive';
  }
  if (mime === 'message/rfc822') {
    return 'email';
  }
  return 'attach_file';
}

export function getTooltip({ filename, mime }) {
  if (filename === 'Bilder') {
    return 'Bild';
  }
  if (/image/.test(mime)) {
    return 'Bild';
  }
  if (/(javascript|json|html|css)/.test(mime)) {
    return 'Kod';
  }
  if (/video/.test(mime)) {
    return 'Video';
  }
  if (/audio/.test(mime)) {
    return 'Ljud';
  }
  if (/(excel|spreadsheet)/.test(mime) || /\.xls/.test(filename)) {
    return 'Kalkylblad';
  }
  if (/word/.test(mime) || /\.doc/.test(filename)) {
    return 'Dokument';
  }
  if (
    /(presentation|presentation|publish)/.test(mime) ||
    /\.ppt/.test(filename)
  ) {
    return 'Presentation';
  }
  if (/pdf/.test(mime)) {
    return 'PDF';
  }
  if (
    /(compress|tar|rar|zip)/.test(mime) ||
    /\.(zip|tar|rar|7zip)/.test(filename)
  ) {
    return 'Arkiv';
  }
  if (mime === 'message/rfc822') {
    return 'Email';
  }
  return 'Fil';
}

export function getColoredIcon({ filename, mime }) {
  if (filename === 'Bilder') {
    return (
      <Icon
        style={{
          color: '#e49e1e',
          width: '20px',
          height: '20px',
          fontSize: '20px',
        }}
      >
        photo_library
      </Icon>
    );
  }
  if (/image/.test(mime)) {
    return (
      <Icon
        style={{
          color: '#e49e1e',
          width: '20px',
          height: '20px',
          fontSize: '20px',
        }}
      >
        photo
      </Icon>
    );
  }
  if (/(javascript|json|html|css)/.test(mime)) {
    return (
      <Icon style={{ width: '20px', height: '20px', fontSize: '20px' }}>
        code
      </Icon>
    );
  }
  if (/video/.test(mime)) {
    return (
      <Icon style={{ width: '20px', height: '20px', fontSize: '20px' }}>
        videocam
      </Icon>
    );
  }
  if (/audio/.test(mime)) {
    return (
      <Icon style={{ width: '20px', height: '20px', fontSize: '20px' }}>
        audiotrack
      </Icon>
    );
  }
  if (/(excel|spreadsheet)/.test(mime) || /\.xls/.test(filename)) {
    return (
      <img
        src='../../images/excel.svg'
        style={{ width: '20px', height: '20px', fontSize: '20px' }}
        alt='excel'
      />
    );
  }
  if (/word/.test(mime) || /\.doc/.test(filename)) {
    return (
      <img
        src='../../images/word.svg'
        style={{ width: '20px', height: '20px', fontSize: '20px' }}
        alt='word'
      />
    );
  }
  if (/pub/.test(mime)) {
    return (
      <img
        src='../../images/publisher.svg'
        style={{ width: '20px', height: '20px', fontSize: '20px' }}
        alt='publisher'
      />
    );
  }
  if (
    /(presentation|presentation|publish)/.test(mime) ||
    /\.ppt/.test(filename)
  ) {
    return (
      <img
        src='../../images/powerpoint.svg'
        style={{ width: '20px', height: '20px', fontSize: '20px' }}
        alt='publisher'
      />
    );
  }
  if (/pdf/.test(mime)) {
    return (
      <Icon
        style={{
          color: '#da1313',
          width: '20px',
          height: '20px',
          fontSize: '20px',
        }}
      >
        picture_as_pdf
      </Icon>
    );
  }
  if (
    /(compress|tar|rar|zip)/.test(mime) ||
    /\.(zip|tar|rar|7zip)/.test(filename)
  ) {
    return (
      <Icon style={{ width: '20px', height: '20px', fontSize: '20px' }}>
        archive
      </Icon>
    );
  }
  if (mime === 'message/rfc822') {
    return (
      <Icon style={{ width: '20px', height: '20px', fontSize: '20px' }}>
        email
      </Icon>
    );
  }
  return (
    <Icon style={{ width: '20px', height: '20px', fontSize: '20px' }}>
      attach_file
    </Icon>
  );
}
