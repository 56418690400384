import { useContext, useState } from 'react';
import { Route } from 'react-router-dom';

import {
  Divider,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';

import MenuItem from '../../components/navigation/menu-item';
import UserContext from '../../contexts/user-context';
import globalRoutes from '../routes';
import { ProjectMap } from './map/project-map';
import routes from './routes';

const ProjectMenuItems = ({ closeDrawer, projectContext, width }) => {
  const user = useContext(UserContext);

  const [openProjectMap, setOpenProjectMap] = useState(false);

  const showRoute = (route, role) => {
    const manageBlasts = projectContext?.project?.manageBlasts;
    return route.allowedRoles
      ? route.isBlastRoute
        ? role === 'SuperAdmin' ||
          role === 'Admin' ||
          (manageBlasts &&
            role === 'ContactPerson' &&
            !projectContext.project.isPropertyOwner)
        : route.allowedRoles.includes(role)
        ? !!(!route.hidden && !route.hasMenu)
        : false
      : !!(!route.hidden && !route.hasMenu);
  };

  if (!user || !user.role) {
    return null;
  }
  const small = isWidthDown('sm', width);

  return (
    <>
      <List>
        {small
          ? globalRoutes
              .filter((r) => !r.hidden)
              .map(
                (r) =>
                  showRoute(r, user.role) && (
                    <MenuItem
                      key={r.name}
                      to={r.path}
                      icon={r.icon}
                      name={r.name}
                      exact={r.exact}
                      closeDrawer={closeDrawer}
                    />
                  )
              )
          : null}
      </List>
      {projectContext?.project && (
        <Route
          path='/project/:project'
          render={({ match }) => (
            <List disablePadding>
              {small && (
                <div>
                  <li>
                    <Divider variant='fullWidth' />
                  </li>
                  <ListItem>
                    <ListItemIcon>
                      <Icon>arrow_drop_down</Icon>
                    </ListItemIcon>
                    <ListItemText>Projekt</ListItemText>
                  </ListItem>
                </div>
              )}

              {routes.map((r) => {
                const { nameInPath, name, icon, exact } = r;
                return (
                  showRoute(r, user.role) && (
                    <MenuItem
                      key={nameInPath}
                      to={`/project/${match.params.project}${
                        nameInPath ? '/' + nameInPath : ''
                      }`}
                      exact={exact}
                      icon={icon}
                      name={name}
                      closeDrawer={closeDrawer}
                      inset={small}
                    />
                  )
                );
              })}
              <ListItem
                button
                onClick={() => {
                  closeDrawer?.();
                  setOpenProjectMap(true);
                }}
              >
                <ListItemIcon>
                  <Icon>location_on</Icon>
                </ListItemIcon>
                <ListItemText inset primary='Visa karta' />
              </ListItem>
              {projectContext?.project != null && (
                <ProjectMap
                  projectNumber={projectContext?.project.referenceNumber}
                  open={openProjectMap}
                  onClose={() => setOpenProjectMap(false)}
                />
              )}
            </List>
          )}
        />
      )}
    </>
  );
};

export default withWidth()(ProjectMenuItems);
