import { useContext, useState } from 'react';
import { withRouter } from 'react-router';

import { Icon, IconButton, Menu, MenuItem } from '@material-ui/core';

import UserContext from '../../contexts/user-context';

export function FaqButton({ history }) {
  const user = useContext(UserContext);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  return (
    <>
      <IconButton
        onClick={(e) => {
          setOpen(!open);
          setAnchorEl(e.currentTarget);
        }}
        color='inherit'
      >
        <Icon fontSize='medium' style={{ color: '#f9f9f9' }}>
          {'help'}
        </Icon>
      </IconButton>
      <Menu
        id='menu-faqs'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => {
          setOpen(false);
          setAnchorEl(null);
        }}
      >
        {user.role === 'ContactPerson' ? (
          ''
        ) : (
          <MenuItem
            component='a'
            onClick={() => {
              setOpen(false);
              setAnchorEl(null);
              history.push('/monitor');
            }}

            //href={process.env.REACT_APP_QBIS_URI}
            //target='_blank'
          >
            Tellus Monitor
          </MenuItem>
        )}

        <MenuItem
          component='a'
          onClick={() => {
            setOpen(false);
            setAnchorEl(null);
            history.push('/faq');
          }}
          //href={process.env.REACT_APP_TELLUS_SKETCH_URI}
          //target='_blank'
        >
          FAQ
        </MenuItem>
      </Menu>
    </>
  );
}

export default withRouter(FaqButton);
