import * as signalR from '@microsoft/signalr';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { IconButton, Menu, MenuItem, Zoom } from '@material-ui/core';
import { Sync, SyncDisabled } from '@material-ui/icons';

import { notify } from '../../common/notifications';
import { getClientId } from '../../common/user';
import { getUserFromLocalStorage } from '../../common/user';
import { DaemonContext } from '../../contexts/daemon-context';

const clientId = getClientId();

const DaemonStatus = () => {
  const [connection, setConnection] = useState();
  const [online, setOnline] = useState(false);

  const [menuOpen, setMenuOpen] = useState(false);
  const anchorEl = useRef();

  const { setDaemonStatus, daemonSavedFile } = useContext(DaemonContext);

  useEffect(() => {
    setDaemonStatus(online ? 'online' : 'offline');
  }, [online, setDaemonStatus]);

  const email = useMemo(() => getUserFromLocalStorage()?.email, []);

  useEffect(() => {
    if (email == null) {
      return;
    }

    const conn = new signalR.HubConnectionBuilder()
      .withUrl(
        `${process.env.REACT_APP_API_URI}events?clientId=${clientId}&email=${email}`
      )
      .configureLogging(signalR.LogLevel.Warning)
      .build();

    (async () => {
      try {
        conn.start();
        setConnection(conn);
      } catch (err) {
        console.error('Failed to connect to signalR.', err);
      }
    })();

    return async () => {
      try {
        await conn.stop();
      } catch (err) {
        console.warn('Failed to stop signalR connection.', err);
      }
      setConnection(null);
    };
  }, [email]);

  useEffect(() => {
    if (connection == null) {
      return;
    }

    connection.on('daemonIsOnline', () => {
      console.info('✅ Daemon is online');
      notify('Tellus Daemon', 'Tellus Daemon är online', 'sync');

      setOnline(true);
    });

    connection.on('daemonIsOffline', () => {
      console.info('🔴 Daemon is offline');
      notify('Tellus Daemon', 'Tellus Daemon är offline', 'warning');

      setOnline(false);
    });

    connection.on('connected', () => {});

    connection.on('fileSaved', (message) => {
      const { FileName: fileName } = JSON.parse(message);
      notify('Tellus Daemon', `Sparade filen ${fileName}`, 'sync');

      daemonSavedFile();
    });

    connection.on('fileOpened', (message) => {
      const { FileName: fileName } = JSON.parse(message);
      notify('Tellus Daemon', `Öppnade filen ${fileName}`, 'sync');
    });

    connection.on('fileClosed', (message) => {
      const { FileName: fileName } = JSON.parse(message);
      notify('Tellus Daemon', `Stängde filen ${fileName}`, 'sync');
    });

    connection.on('fileUploaded', (message) => {
      const { FileName: fileName } = JSON.parse(message);
      notify('Tellus Daemon', `Laddade upp filen ${fileName}`, 'sync');
    });

    return () => {
      try {
        connection.off('daemonIsOnline');
        connection.off('daemonIsOffline');
        connection.off('connected');
        connection.off('fileSaved');
        connection.off('fileOpened');
        connection.off('fileClosed');
        connection.off('fileUploaded');
      } catch (err) {
        console.warn('Failed to unregister signalR handlers.', err);
      }
    };
  }, [connection, daemonSavedFile]);

  const openMenu = useCallback(() => setMenuOpen(true), []);
  const closeMenu = useCallback(() => setMenuOpen(false), []);

  const handleStop = useCallback(async () => {
    setMenuOpen(false);

    // Stop the daemon that runs locally on users computer
    await fetch('http://localhost:8080/api/stop');

    setOnline(false);
  }, []);

  return (
    <>
      <IconButton ref={anchorEl} onClick={openMenu} style={{ color: 'white' }}>
        <Zoom in={online}>
          <Sync
            color='secondary'
            style={{ display: !online ? 'none' : 'block' }}
          />
        </Zoom>
        <Zoom in={!online} timeout={{ exit: 0 }}>
          <SyncDisabled
            color='error'
            style={{ display: online ? 'none' : 'block' }}
          />
        </Zoom>
      </IconButton>
      <Menu
        id='daemon-menu'
        anchorEl={anchorEl.current}
        open={menuOpen}
        onClose={closeMenu}
      >
        {online ? (
          [
            <MenuItem key='stop' onClick={handleStop}>
              Stoppa daemon
            </MenuItem>,
            <MenuItem key='show' disabled>
              Visa händelser
            </MenuItem>,
          ]
        ) : (
          <MenuItem
            component='a'
            href={`${process.env.REACT_APP_LEGACY_API_URI}/api/download?filename=TellusDaemon.bat&email=${email}&clientId=${clientId}`}
            onClick={closeMenu}
          >
            Ladda ner daemon
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

export default DaemonStatus;
