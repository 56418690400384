import { gql } from '@apollo/client';
import { Mutation } from '@apollo/client/react/components';
import gravatarUrl from 'gravatar-url';
import { useContext, useEffect, useState } from 'react';

import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { handleError } from '../common/graphql';
import UserContext from '../contexts/user-context';
import { UPDATE_TOKEN } from '../pages';
import DialogWrapper from './dialog-wrapper';

const styles = (theme) => ({
  item: {
    width: '50%',
  },
  avatar: {
    borderRadius: theme.spacing(1),
  },
  errorText: {
    color: 'red',
    fontWeight: 'bold',
  },
});

const UPDATE_PROFILE = gql`
  mutation UpdateProfile($profileInput: ProfileInput!) {
    updateProfile(profile: $profileInput)
  }
`;

const ProfileDialog = ({ classes, open, onClose }) => {
  const userContext = useContext(UserContext);
  const [errorDisplay, setErrorDisplay] = useState(null);
  const [user, setUser] = useState({
    id: 0,
    email: '',
    name: '',
    phonenumber: '',
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    userContext.email &&
      setUser((u) => ({
        ...u,
        id: parseInt(userContext.id),
        email: userContext.email,
        name: userContext.name,
        phonenumber: userContext.phonenumber,
      }));
  }, [userContext]);

  const handleUpdate = async (event, updateProfile, client) => {
    event.preventDefault();

    if (user.password !== user.confirmPassword) {
      setErrorDisplay(
        'Lösenordsfälten måste matcha för att kunna spara ett nytt lösenord'
      );
      return;
    }

    let hasError = false;
    try {
      await updateProfile({
        variables: {
          profileInput: {
            id: user.id,
            name: user.name,
            email: user.email,
            phonenumber: user.phonenumber,
            password: user.password,
          },
        },
      });
    } catch (err) {
      hasError = true;
      setErrorDisplay(handleError(err));
    }

    const { data } = await client.mutate({
      mutation: UPDATE_TOKEN,
      variables: {
        email: user.email,
      },
    });

    const token = data.updateToken;

    localStorage.setItem('token', token);

    if (!hasError) {
      setErrorDisplay(null);
      onClose();
    }
  };

  return (
    <Mutation mutation={UPDATE_PROFILE}>
      {(updateProfile, { data, loading, error, client }) => (
        <DialogWrapper
          open={open}
          onClose={() => {
            setErrorDisplay(null);
            onClose();
          }}
          loading={loading}
        >
          <form
            onSubmit={(event) => handleUpdate(event, updateProfile, client)}
          >
            <DialogTitle>Min profil</DialogTitle>
            <DialogContent>
              <Grid container spacing={2} direction='row' wrap='nowrap'>
                <Grid item className={classes.item}>
                  <Typography variant='subtitle1'>Profilbild</Typography>
                  <img
                    style={{ minHeight: 200 }}
                    src={
                      user.email &&
                      gravatarUrl(user.email, {
                        size: 200,
                        default: 'mm',
                      })
                    }
                    alt='Profilbild'
                    className={classes.avatar}
                  />
                  <Typography gutterBottom variant='body2'>
                    Metron.se använder{' '}
                    <a href='http://gravatar.com/'> Gravatar </a> för
                    profilbilder. Din bild är kopplad till din epostadress och
                    kan användas på flera hemsidor.
                  </Typography>
                  <Typography gutterBottom variant='body2'>
                    Om du inte har ett Gravatar konto, kan du
                    <a href='http://sv.gravatar.com/site/signup/'>
                      {' '}
                      skapa ett gratis konto här.
                    </a>
                  </Typography>
                </Grid>
                <Grid item className={classes.item}>
                  {' '}
                  <Typography variant='subtitle1'>Kontouppgifter</Typography>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <TextField
                      className={classes.textField}
                      name='name'
                      onChange={(e) =>
                        setUser({ ...user, name: e.target.value })
                      }
                      value={user.name || ''}
                      label='Namn'
                      margin='normal'
                    />
                    <TextField
                      className={classes.textField}
                      name='email'
                      onChange={(e) =>
                        setUser({ ...user, email: e.target.value })
                      }
                      value={user.email || ''}
                      label='E-post'
                      type='email'
                      margin='normal'
                    />
                    <TextField
                      className={classes.textField}
                      name='phonenumber'
                      onChange={(e) =>
                        setUser({ ...user, phonenumber: e.target.value })
                      }
                      value={user.phonenumber || ''}
                      label='Telefonnummer'
                      margin='normal'
                    />
                    <TextField
                      className={classes.textField}
                      name='password'
                      onChange={(e) =>
                        setUser({ ...user, password: e.target.value })
                      }
                      value={user.password || ''}
                      label='Nytt lösenord'
                      type='password'
                      autoComplete='new-password'
                      margin='normal'
                    />
                    <TextField
                      className={classes.textField}
                      name='confirmPassword'
                      onChange={(e) =>
                        setUser({ ...user, confirmPassword: e.target.value })
                      }
                      value={user.confirmPassword || ''}
                      label='Bekräfta nytt lösenord'
                      type='password'
                      autoComplete='new-password'
                      margin='normal'
                    />
                  </div>
                  {errorDisplay && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: '20px',
                      }}
                    >
                      <span className={classes.errorText}>{errorDisplay}</span>
                    </div>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setErrorDisplay(null);
                  onClose();
                }}
              >
                Avbryt
              </Button>

              <Button
                color='primary'
                variant='contained'
                disabled={loading}
                type='submit'
              >
                Spara profil
              </Button>
            </DialogActions>
          </form>
        </DialogWrapper>
      )}
    </Mutation>
  );
};

export default withStyles(styles)(ProfileDialog);
