import { Mutation } from '@apollo/client/react/components';
import { isWithinInterval } from 'date-fns';
import format from 'date-fns/format';
import { useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';

import {
  CircularProgress,
  Grid,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { NotificationImportant, Star } from '@material-ui/icons';

import UserContext from '../../contexts/user-context';
import ProjectNotificationsWidget, {
  aggregateNotifications,
} from '../project/dashboard/project-notifications-widget';
import {
  FAVOURITE_PROJECT,
  GET_FAV_PROJECTS,
  GET_PROJECTS,
  UNFAVOURITE_PROJECT,
} from './home';

const styles = (theme) => ({
  htmlPopper: {},
  htmlTooltip: {
    backgroundColor: 'transparent',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    '& b': {
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  1: {
    color: theme.palette.error.dark,
  },
  2: {
    color: '#e4a006',
  },
  3: {
    color: theme.palette.success.dark,
  },
  iconRow: {
    display: 'inline-block',
  },
});
const Secondary = ({ county, loading, startDate, endDate, customer }) => {
  return (
    <Grid container direction='row' justifyContent='space-between'>
      <Typography color='textSecondary'>
        {loading ? (
          <Skeleton width={200} />
        ) : (
          `${customer && customer.name}, ${county}`
        )}
      </Typography>

      <Typography color='textSecondary'>
        {loading ? (
          <Skeleton width={155} />
        ) : (
          `${format(new Date(startDate), 'yyyy-MM-dd')} — ${format(
            new Date(endDate),
            'yyyy-MM-dd'
          )}`
        )}
      </Typography>
    </Grid>
  );
};

const ProjectListItem = ({
  project,
  clickCallback,
  history,
  loading = false,
  data,
  selected,
  buttonRef,
  classes,
}) => {
  const user = useContext(UserContext);
  const { id, name, referenceNumber, isFavourite } = project;
  if (data && data.project && data.project.measuringCards) {
    let now = new Date();
    data.project.measuringCards.forEach((c) => ({
      ...c,
      isDormant: !isWithinInterval(now, {
        start: new Date(c.validFrom),
        end: new Date(c.validTo),
      }),
    }));
  }
  let warnings =
    user &&
    (user.role === 'Admin' || user.role === 'SuperAdmin') &&
    !loading &&
    data
      ? aggregateNotifications(data)
      : [];

  return (
    <ListItem
      selected={selected}
      button
      divider
      ref={buttonRef}
      onClick={() => {
        if (clickCallback) clickCallback();
        history.push(
          `/project/${referenceNumber}${
            user && (user.role === 'Admin' || user.role === 'SuperAdmin')
              ? ''
              : '/measuringdata'
          }`
        );
      }}
    >
      <Typography variant='h6' color='textSecondary'>
        {referenceNumber || <Skeleton width={60} />}
      </Typography>
      <ListItemText
        disableTypography
        primary={
          <div style={{ display: 'inline' }}>
            <Typography
              variant='subtitle1'
              component='span'
              style={{ display: 'inline' }}
            >
              {name || <Skeleton width={120} />}
            </Typography>
            {(user && user.role === 'Admin') ||
              (user.role === 'SuperAdmin' && loading && (
                <CircularProgress
                  style={{ width: '20px', height: '20px', marginLeft: '20px' }}
                />
              ))}
            {warnings.length > 0 && (
              <Tooltip
                classes={{
                  popper: classes.htmlPopper,
                  tooltip: classes.htmlTooltip,
                }}
                title={
                  <>
                    <ProjectNotificationsWidget
                      classes={classes}
                      referenceNumber={referenceNumber}
                    />
                  </>
                }
              >
                <NotificationImportant
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '10px',
                    verticalAlign: 'sub',
                    color: '#e49601',
                  }}
                />
              </Tooltip>
            )}
          </div>
        }
        secondary={<Secondary {...project} />}
        inset
      />
      <ListItemSecondaryAction>
        <Mutation
          mutation={isFavourite ? UNFAVOURITE_PROJECT : FAVOURITE_PROJECT}
          refetchQueries={(x) => [
            {
              query: GET_FAV_PROJECTS,
            },
            {
              query: GET_PROJECTS,
            },
          ]}
        >
          {(favouriteProject) => (
            <IconButton
              aria-label='Add to favorites'
              color={isFavourite ? 'secondary' : 'default'}
              onClick={(x) => favouriteProject({ variables: { id } })}
            >
              <Star />
            </IconButton>
          )}
        </Mutation>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default withStyles(styles)(withRouter(ProjectListItem));
