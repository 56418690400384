import 'cal-heatmap/cal-heatmap.css';
import 'chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.css';
import 'chartist/dist/chartist.css';
import ReactDOM from 'react-dom';
import WebFont from 'webfontloader';

import { ApolloClientProvider } from './apollo-client-provider';
import { AppInsightsProvider } from './app-insights-provider';
import { getTokenFromLocalStorage } from './common/user';
import { OpenAPI as TellUsApiOptions } from './generated/tellus';
import Index from './pages/index';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './style.css';
import { setYupLocale } from './yup-locale';

const config = {
  google: {
    families: ['Roboto:300,400,500', 'Archivo Narrow'],
  },
};

WebFont.load(config);

setYupLocale();

// Init TellUs API client.
TellUsApiOptions.BASE = process.env.REACT_APP_API_URI;
TellUsApiOptions.TOKEN = async () => getTokenFromLocalStorage();

ReactDOM.render(
  <AppInsightsProvider>
    <ApolloClientProvider>
      <Index />
    </ApolloClientProvider>
  </AppInsightsProvider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
