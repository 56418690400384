import classNames from 'classnames';
import React from 'react';
import Dropzone from 'react-dropzone';
import { InView } from 'react-intersection-observer';

import {
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';

import DocumentListItem from '../../../components/document-list-item';

export default class DocumentFolder extends React.Component {
  state = {
    changeCount: 0,
    hover: {},
  };

  toggleOpen(subpath, handleOpenItem) {
    let subfolder = this.props.path === subpath ? null : subpath;
    handleOpenItem(subfolder);
  }

  onDragStart() {
    return (e) => {
      e.preventDefault();
      if (this.props.isSubfolder) e.stopPropagation();
    };
  }

  onDragEnter(folderName) {
    return (e) => {
      e.preventDefault();
      if (this.props.isSubfolder) e.stopPropagation();
      this.setState((state) => {
        const hover = { ...state.hover };
        const changeCount = state.changeCount + (!hover[folderName] ? 1 : 0);
        hover[folderName] = true;
        return { hover, changeCount };
      });
    };
  }

  onDragLeave(folderName) {
    return (e) => {
      e.preventDefault();
      if (this.props.isSubfolder) e.stopPropagation();
      this.setState((state) => {
        const hover = { ...state.hover };
        const changeCount = state.changeCount + (hover[folderName] ? 1 : 0);
        hover[folderName] = false;
        return { hover, changeCount };
      });
    };
  }

  onDragOver() {
    return (e) => {
      e.preventDefault();
      if (this.props.isSubfolder) e.stopPropagation();
    };
  }

  onDrop(folderName) {
    return (accepted, rejected, e) => {
      e.preventDefault();
      if (this.props.isSubfolder) e.stopPropagation();
      this.setState((state) => {
        const hover = { ...state.hover };
        hover[folderName] = false;
        return { hover };
      });

      this.props.onFilesDropped(`${this.props.path}\\${folderName}`, accepted);
    };
  }

  render() {
    const {
      path,
      folder,
      isSubfolder,
      removeDocument,
      classes,
      handleOpenItem,
      isMoveOrCopyView,
      refetching,
      sortBy,
      disabled,
      handleError,
    } = this.props;

    const leafUploadFolder =
      path &&
      path
        .split('\\')
        .filter((p) => p !== '')
        .slice(-1)[0];
    const renderSubfolder = (folderName, subpath, handleOpenItem, open) => {
      const subfolder = folder.subfolders[folderName];
      return (
        <>
          <ListItem
            key={`subfolder-item-${subpath}`}
            button
            onClick={() => {
              this.toggleOpen(subpath, handleOpenItem);
            }}
            style={{ paddingLeft: 10 }}
          >
            {isMoveOrCopyView ? (
              <>
                <ListItemIcon>
                  <Icon
                    className={classNames(!classes.emptyFolder)}
                    style={{ width: '18px', height: '18px', fontSize: '18px' }}
                  >
                    {path === subpath ? 'folder_open' : 'folder'}
                  </Icon>
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={folderName}
                  primaryTypographyProps={{
                    className: classNames(!classes.emptyFolder),
                  }}
                />
              </>
            ) : (
              <>
                <ListItemIcon>
                  <Icon
                    className={classNames(
                      !subfolder.hasDocs && classes.emptyFolder
                    )}
                  >
                    {path === subpath ? 'folder_open' : 'folder'}
                  </Icon>
                </ListItemIcon>
                <ListItemText
                  inset
                  primary={folderName}
                  primaryTypographyProps={{
                    className: classNames(
                      !subfolder.hasDocs && classes.emptyFolder
                    ),
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      open();
                    }}
                  >
                    <Icon color='disabled'>cloud_upload</Icon>
                  </IconButton>
                </ListItemSecondaryAction>
              </>
            )}
          </ListItem>
          <DocumentFolder
            key={`subfolder-${subpath}`}
            classes={classes}
            path={path}
            folder={subfolder}
            isSubfolder={Object.keys(subfolder.subfolders).length === 0}
            open={path === subpath}
            onFilesDropped={this.props.onFilesDropped}
            removeDocument={removeDocument}
            handleOpenItem={handleOpenItem}
          />
        </>
      );
    };

    return (
      this.props.open && (
        <List
          key={`folder-${folder.name}`}
          className={classNames(isSubfolder && classes.nested)}
          disablePadding
        >
          {Object.keys(folder.subfolders)
            .sort(
              (a, b) => folder.subfolders[a].order - folder.subfolders[b].order
            )
            .map((folderName) => {
              let folderPath = folder.path
                ? `${folder.path}\\${folderName}`
                : `\\${folderName}`;

              if (path && path !== '' && !folderPath.startsWith(path)) {
                return null;
              }

              return (
                <Dropzone
                  key={`subfolder-${folderName}`}
                  disableClick
                  disabled={disabled}
                  onDragStart={this.onDragStart(folderName)}
                  onDragEnter={this.onDragEnter(folderName)}
                  onDragLeave={this.onDragLeave(folderName)}
                  onDragOver={this.onDragOver(folderName)}
                  onDrop={this.onDrop(folderName)}
                  className={classNames(
                    this.state.hover[folderName] && classes.folderHover
                  )}
                  style={{}}
                >
                  {({ open }) => {
                    return renderSubfolder(
                      folderName,
                      folderPath,
                      handleOpenItem,
                      open
                    );
                  }}
                </Dropzone>
              );
            })}
          <Dropzone
            key={`subfolder-leaf-${leafUploadFolder}`}
            disableClick
            disabled={disabled}
            onDragStart={this.onDragStart(leafUploadFolder)}
            onDragEnter={this.onDragEnter(leafUploadFolder)}
            onDragLeave={this.onDragLeave(leafUploadFolder)}
            onDragOver={this.onDragOver(leafUploadFolder)}
            onDrop={this.onDrop('')}
            className={classNames(
              this.state.hover[leafUploadFolder] && classes.folderHover
            )}
            style={{}}
          >
            {this.props.open &&
            (Object.keys(folder.subfolders).length > 0 ||
              folder.docs.length > 0) ? (
              folder.docs
                .sort(
                  sortBy === 'date'
                    ? function (a, b) {
                        return a.modifiedAt < b.modifiedAt
                          ? 1
                          : a.modifiedAt > b.modifiedAt
                          ? -1
                          : 0;
                      }
                    : function (a, b) {
                        let aName =
                          a.filename === 'Bilder'
                            ? `Album: ${a.tags}`
                            : a.filename.toUpperCase();
                        let bName =
                          b.filename === 'Bilder'
                            ? `Album: ${b.tags}`
                            : b.filename.toUpperCase();

                        return aName < bName ? -1 : aName > bName ? 1 : 0;
                      }
                )
                .map((doc, i) => (
                  <InView key={doc.id}>
                    {({ inView, ref }) =>
                      inView ? (
                        isMoveOrCopyView ? (
                          !doc.id ? (
                            <div ref={ref} key={doc.id}>
                              <DocumentListItem
                                key={doc.id}
                                doc={doc}
                                removeDocument={removeDocument}
                                isFirst={i === 0}
                                handleError={handleError}
                              />
                            </div>
                          ) : (
                            ''
                          )
                        ) : (
                          <div ref={ref}>
                            <DocumentListItem
                              key={doc.id}
                              doc={doc}
                              removeDocument={removeDocument}
                              handleSelectDocument={
                                this.props.handleSelectDocument
                              }
                              selectedDocuments={this.props.selectedDocuments}
                              showCheckboxes={this.props.showCheckboxes}
                              refetching={refetching}
                              isFirst={i === 0}
                              handleError={handleError}
                            />
                          </div>
                        )
                      ) : (
                        <div style={{ height: '49px' }} ref={ref} />
                      )
                    }
                  </InView>
                ))
            ) : !isMoveOrCopyView ? (
              <ListItem>
                <ListItemText
                  primary='Här är det tomt än så länge'
                  primaryTypographyProps={{
                    className: classNames([
                      classes.emptyFolder,
                      classes.emphasis,
                    ]),
                  }}
                />
              </ListItem>
            ) : (
              <ListItem>
                <ListItemText primary='Tryck på spara för att flytta filen hit' />
              </ListItem>
            )}
          </Dropzone>
        </List>
      )
    );
  }
}
