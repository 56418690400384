import {
  LayerProps,
  createTileLayerComponent,
  updateGridLayer,
  withPane,
} from '@react-leaflet/core';
import { Coords, DoneCallback, TileLayer, TileLayerOptions } from 'leaflet';

import { getTokenFromLocalStorage } from '../../common/user';

export interface LantmaterietTileLayerProps
  extends TileLayerOptions,
    LayerProps {
  url?: string;
}

export const LantmaterietTileLayer = createTileLayerComponent<
  TileLayerImpl,
  LantmaterietTileLayerProps
>(
  ({ url, ...options }, context) => ({
    instance: new TileLayerImpl(url ?? '', withPane(options, context)),
    context,
  }),
  updateGridLayer
);
LantmaterietTileLayer.defaultProps = {
  url: `${process.env.REACT_APP_API_URI}Map/Tile/3006/{z}/{y}/{x}`,
  attribution: '&copy; <a href="https://lantmateriet.se/">Lantmäteriet</a>',
  maxNativeZoom: 13,
};

class TileLayerImpl extends TileLayer {
  protected createTile(coords: Coords, done: DoneCallback): HTMLElement {
    const tile = document.createElement('img');

    const url = new URL(this.getTileUrl(coords), window.location.origin);
    const token = getTokenFromLocalStorage();

    if (token != null) {
      url.searchParams.set('auth', token);
    }

    tile.src = url.toString();

    tile.onload = () => done(undefined, tile);

    return tile;
  }
}
