import React from 'react';

const UserContext = React.createContext({
  id: null,
  email: null,
  phonenumber: null,
  name: null,
  role: null,
  allowedProjectIds: null,
  allowedMeasuringPoints: null,
});

export default UserContext;
