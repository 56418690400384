import styled from '@emotion/styled';
import { format, parseISO } from 'date-fns';
import L from 'leaflet';
import { VFC } from 'react';
import { createPortal } from 'react-dom';
import { MdBolt } from 'react-icons/md';

import { Link, useTheme } from '@material-ui/core';

import { documentDownloadUrl } from '../../../common/tellus-util';
import {
  MarkerBackground,
  MarkerColor,
  MarkerIcon,
  createMarkerIcon,
} from '../../../components/map/icon-marker';
import { BlastWithColorDto } from '../../../generated/tellus';
import { Highlightable, createHighlightable } from './highlightable';

const getBlastColor = (blast: BlastWithColorDto) =>
  blast.blastColor === 'yellow'
    ? 'beige'
    : (blast.blastColor as MarkerColor | undefined | null) ?? 'gray';

export interface BlastMarker extends Highlightable {
  blast: BlastWithColorDto;
  marker: L.Marker;
  component: JSX.Element;
}

export const createBlastMarker = (
  projectNumber: string,
  blast: BlastWithColorDto,
  map: L.Map
): BlastMarker => {
  const icon = createMarkerIcon(true);
  const marker = new L.Marker([blast.latitude, blast.longitude], {
    icon: icon,
  });
  const popup = document.createElement('div');

  marker.bindTooltip(blast.name, { direction: 'top' });
  marker.bindPopup(popup);

  const component = (
    <BlastMarkerComponent
      projectNumber={projectNumber}
      key={blast.id}
      blast={blast}
      icon={icon}
      popup={popup}
    />
  );

  return {
    blast,
    marker,
    component,
    ...createHighlightable(map, marker, 1),
  };
};

const PopupTitle = styled.div({
  display: 'flex',
  paddingBottom: 16,
  fontSize: 16,
  fontWeight: 800,
  alignItems: 'center',
});

const DivGrid = styled.div<{
  gap: number;
}>((props) => ({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  gap: props.gap,
}));

interface BlastMarkerComponentProps {
  projectNumber: string;
  blast: BlastWithColorDto;
  icon: MarkerIcon;
  popup: HTMLElement;
}

const BlastMarkerComponent: VFC<BlastMarkerComponentProps> = ({
  projectNumber,
  blast,
  icon,
  popup,
}) => {
  const theme = useTheme();

  return (
    <>
      {createPortal(
        <MarkerBackground iconColor='white' markerColor={getBlastColor(blast)}>
          <MdBolt />
        </MarkerBackground>,
        icon.icon
      )}
      {createPortal(
        <>
          <PopupTitle>
            <MdBolt size={20} style={{ marginRight: theme.spacing(0.5) }} />
            Salva {blast.name}
          </PopupTitle>

          <DivGrid gap={theme.spacing(1)}>
            <span>Tid:</span>
            <span style={{ whiteSpace: 'nowrap' }}>
              {format(parseISO(blast.dateTime), 'yyyy-MM-dd hh:mm:ss')}
            </span>

            {blast.comment && (
              <>
                <div>Kommentar:</div>
                <div>{blast.comment}</div>
              </>
            )}

            {blast.subProjectNumber && (
              <>
                <span>Delprojektnummer/ entreprenaddel:</span>
                <span>{blast.subProjectNumber}</span>
              </>
            )}

            {blast.journalDocumentId && (
              <>
                <span>Sprängjournal:</span>
                <a href={documentDownloadUrl(blast.journalDocumentId)}>
                  Ladda ner
                </a>
              </>
            )}

            {/* Empty span to align link to the right. */}
            <span></span>
            <Link
              href={`/project/${projectNumber}/measuringdata?blast=${blast.id}`}
              target='_blank'
            >
              Visa mätdata
            </Link>
          </DivGrid>
        </>,
        popup
      )}
    </>
  );
};
