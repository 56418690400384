import decode from 'jwt-decode';
import uuid from 'uuid/v4';

const getClientId = () => {
  const clientId = localStorage.getItem('clientId');

  if (clientId) {
    return clientId;
  }

  const newClientId = uuid();
  localStorage.setItem('clientId', newClientId);

  return newClientId;
};

const getUserFromToken = (token) => {
  let user = null;

  if (token) {
    user = decode(token);
    user.__typename = 'User';
  }

  return user;
};

const getTokenFromLocalStorage = () => {
  return localStorage.getItem('token');
};

const removeTokenFromLocalStorage = () => {
  localStorage.removeItem('token');
};

const getUserFromLocalStorage = () => {
  const token = getTokenFromLocalStorage();
  return getUserFromToken(token);
};

const setTokenInLocalStorage = (token) => {
  localStorage.setItem('token', token);
};

const isUserTokenValid = (user) => {
  const expireDate = new Date(user.exp * 1000);
  return expireDate > new Date();
};

export {
  getUserFromToken,
  getUserFromLocalStorage,
  getTokenFromLocalStorage,
  removeTokenFromLocalStorage,
  getClientId,
  setTokenInLocalStorage,
  isUserTokenValid,
};
