/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlastWithColorDto } from '../models/BlastWithColorDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

  /**
   * @returns BlastWithColorDto Success
   * @throws ApiError
   */
  public static blastWithColor({
    referenceNumber,
  }: {
    referenceNumber: string,
  }): CancelablePromise<Array<BlastWithColorDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v2/projects/{referenceNumber}/blastsWithColor',
      path: {
        'referenceNumber': referenceNumber,
      },
    });
  }

}
