import { gql } from '@apollo/client';
import React from 'react';

import { Grid } from '@material-ui/core';

import ProjectDialog from './project-dialog';
import ProjectList from './project-list';

export const GET_PROJECTS = gql`
  query Projects($cursor: String, $first: Int = 10) {
    projects(first: $first, after: $cursor) {
      edges {
        node {
          id
          name
          referenceNumber
          isFavourite
          county
          startDate
          endDate
          manageBlasts
          customer {
            id
            name
          }
          office {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const GET_FAV_PROJECTS = gql`
  query FavouriteProjects($cursor: String, $first: Int = 20) {
    favouriteProjects(first: $first, after: $cursor) {
      edges {
        node {
          id
          name
          referenceNumber
          isFavourite
          county
          startDate
          endDate
          manageBlasts
          customer {
            id
            name
          }
          office {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;

export const FAVOURITE_PROJECT = gql`
  mutation FavouriteProject($id: Int!) {
    favouriteProject(id: $id)
  }
`;

export const UNFAVOURITE_PROJECT = gql`
  mutation UnfavouriteProject($id: Int!) {
    unfavouriteProject(id: $id)
  }
`;

class Home extends React.Component {
  state = {
    isProjectModalOpen: false,
  };

  handleProjectModalOpen = () => {
    this.setState({
      isProjectModalOpen: true,
    });
  };

  handleProjectModalClose = () => {
    this.setState({
      isProjectModalOpen: false,
    });
  };

  render() {
    const { isProjectModalOpen } = this.state;
    return (
      <Grid container spacing={3} justifyContent='center'>
        <ProjectList
          query={GET_FAV_PROJECTS}
          title='Favoritprojekt'
          projectsNameInObject='favouriteProjects'
          numberOfSkeletons={3}
        />
        <ProjectList
          query={GET_PROJECTS}
          title='Senaste projekten'
          projectsNameInObject='projects'
          numberOfSkeletons={10}
          newButtonText='Nytt projekt'
          newButtonCallback={this.handleProjectModalOpen}
        />
        <ProjectDialog
          isProjectModalOpen={isProjectModalOpen}
          handleProjectModalClose={this.handleProjectModalClose}
          refetchLists={[GET_FAV_PROJECTS, GET_PROJECTS]}
        />
      </Grid>
    );
  }
}

export default Home;
