import { useContext, useMemo } from 'react';
import { withRouter } from 'react-router';

import { Drawer, Hidden, withStyles } from '@material-ui/core';

import ProjectContext from '../../contexts/project-context';
import ProjectMenuItems from '../../pages/project/project-menu-items';
import logo from './metron_logo.png';
import styles from './styles';

function SideNavigation({ classes, drawerOpen, setDrawerOpen, history }) {
  let projectContext = useContext(ProjectContext);

  const drawer = useMemo(() => {
    return (
      <>
        <img
          onClick={() => history.push('/')}
          src={logo}
          alt='Logo'
          className={classes.logo}
        />
        <ProjectMenuItems
          projectContext={projectContext}
          closeDrawer={() => setDrawerOpen(false)}
        />
      </>
    );
  }, [projectContext, history, classes.logo, setDrawerOpen]);

  return (
    <>
      <Hidden mdUp>
        <Drawer
          variant='temporary'
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          classes={{
            paper: classes.drawerPaper,
            docked: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          classes={{
            paper: classes.drawerPaper,
            docked: classes.drawerPaper,
          }}
          variant='permanent'
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
}

export default withStyles(styles)(withRouter(SideNavigation));
