import { createContext, useContext } from 'react';

interface ProjectProps {
  id: number;
  referenceNumber: string;
  name: string;
  manageBlasts: unknown;
  isPropertyOwner: boolean;
}

interface ProjectContextProps {
  project: ProjectProps;
  setProject: (newProject: ProjectProps) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(
  undefined
);

export const useProjectContext = () => {
  const context = useContext(ProjectContext);

  if (context === undefined) {
    throw new Error('ProjectContext is not set');
  }

  return context;
};

export default ProjectContext;
