export const notEmpty = <T>(value: T | null | undefined): value is T =>
  value != null;

export const EpochDate = new Date(0);

export const formatMeasuringPointNumber = (number: number) =>
  number.toString().replace(',', '.');

export interface NamedValue<T = any> {
  value: T;
  name: string;
}
