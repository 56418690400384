import { gql, useApolloClient } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import locale from 'date-fns/locale/sv';
import { Suspense, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch, matchPath, withRouter } from 'react-router-dom';

import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/core/styles';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import '@progress/kendo-theme-material/dist/all.css';

import {
  getUserFromLocalStorage,
  isUserTokenValid,
  removeTokenFromLocalStorage,
  setTokenInLocalStorage,
} from '../common/user';
import ErrorBoundary from '../components/error-boundary';
import Header from '../components/navigation/header';
import SideNavigation from '../components/navigation/side-navigation';
import SearchResults from '../components/search-results';
import WaitingComponent from '../components/waiting-component';
import { DaemonProvider } from '../contexts/daemon-context';
import ProjectContext from '../contexts/project-context';
import { UploadProvider } from '../contexts/upload-context';
import UserContext from '../contexts/user-context';
import withRoot from '../withRoot';
import NotFoundPage from './not-found';
import routes from './project/routes';
import globalRoutes from './routes';
import UnauthorizedPage from './unauthorized';
import { UpdateNotification } from './update-notification';

export const UPDATE_TOKEN = gql`
  mutation UpdateToken {
    updateToken
  }
`;

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
    '@media print': {
      marginTop: 0,
    },
  },
  headerWrapper: {
    width: '100%',
  },
  globalNavigation: {
    position: 'absolute',
    top: '64px',
    left: 0,
    right: 0,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  sideNavigation: {
    width: 280,
    height: '100%',
    zIndex: 1,
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  content: {
    padding: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 1),
    },
    '@media print': {
      marginTop: 0,
      padding: 0,
      overflowY: 'hidden',
    },
  },
  div: {
    margin: '0 auto',
    maxWidth: 700,
    width: '100%',
  },
});

function Index({ classes, history, width }) {
  const client = useApolloClient();
  const appInsights = useAppInsightsContext();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [project, setProject] = useState(null);

  const currentRoute = globalRoutes
    .concat(routes)
    .find((r) => matchPath(history.location.pathname, r));

  useEffect(() => {
    setInterval(
      (function checkToken() {
        const user = getUserFromLocalStorage();
        if (user !== null) {
          const isValid = isUserTokenValid(user);
          if (isValid) {
            client
              .mutate({
                mutation: UPDATE_TOKEN,
                update: (_, mutationResult) => {
                  if (mutationResult.data?.updateToken) {
                    setTokenInLocalStorage(mutationResult.data.updateToken);
                    console.log('🚀 Token has been renewed');
                  }
                },
              })
              .catch((error) => {
                appInsights.trackException(
                  {
                    exception: error,
                    severityLevel: SeverityLevel.Error,
                  },
                  { message: 'Failed to update token.' }
                );

                removeTokenFromLocalStorage();
                history.push(`/login?path=${window.location.pathname}`);
              });
          } else {
            console.log('Token has expired. Redirecting to login');
            removeTokenFromLocalStorage();
            history.push(`/login?path=${window.location.pathname}`);
          }
        }
        return checkToken;
      })(),
      7200000
    );
  }, [appInsights, client, history]);

  const ProtectedRoute = ({
    component: Component,
    allowedRoles: roles,
    role,
    user,
    ...rest
  }) => {
    useEffect(() => {
      if (!user) {
        history.push(`/login?path=${window.location.pathname}`);
      }
    }, [user]);

    return (
      <Route
        {...rest}
        render={(props) => {
          return roles.includes(role) ? (
            <Component {...props} />
          ) : (
            <Route component={UnauthorizedPage} />
          );
        }}
      />
    );
  };

  const user = getUserFromLocalStorage();
  const isAuthenticated = user && isUserTokenValid(user);

  // Must check user before redirecting or we will end up in an redirect loop.
  if (user && !isUserTokenValid(user)) {
    removeTokenFromLocalStorage();
    history.push(`/login?path=${window.location.pathname}`);
  }

  return (
    <>
      <CssBaseline />

      <UploadProvider>
        <DaemonProvider>
          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <UserContext.Provider value={user}>
              <ProjectContext.Provider
                value={{
                  project: project,
                  setProject: (newProject) => setProject(newProject),
                }}
              >
                <Helmet>
                  <title>TellUs</title>
                </Helmet>

                {!currentRoute?.hideHeader && (
                  <Header
                    drawerOpen={drawerOpen}
                    setDrawerOpen={setDrawerOpen}
                    currentRoute={currentRoute}
                    client={client}
                  />
                )}

                <div className={!currentRoute.hideHeader ? classes.root : ''}>
                  {(isWidthDown('sm', width) || currentRoute?.hasMenu) && (
                    <nav className={classes.sideNavigation}>
                      <SideNavigation
                        drawerOpen={drawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        history={history}
                      />
                    </nav>
                  )}
                  <main className={`${classes.content} `} id='main'>
                    <ErrorBoundary>
                      <div className={classes.div}>
                        {isAuthenticated && <SearchResults />}
                      </div>
                      <Suspense fallback={''}>
                        <Switch>
                          {globalRoutes.map(
                            ({ exact, path, component, name, allowedRoles }) =>
                              allowedRoles ? (
                                <ProtectedRoute
                                  exact={exact}
                                  path={path}
                                  component={WaitingComponent(component)}
                                  key={name}
                                  role={user && user.role}
                                  user={user}
                                  allowedRoles={allowedRoles}
                                />
                              ) : (
                                <Route
                                  exact={exact}
                                  path={path}
                                  component={WaitingComponent(component)}
                                  key={name}
                                />
                              )
                          )}
                          <Route component={NotFoundPage} />
                        </Switch>
                      </Suspense>
                      <UpdateNotification />
                    </ErrorBoundary>
                  </main>
                </div>
              </ProjectContext.Provider>
            </UserContext.Provider>
          </MuiPickersUtilsProvider>
        </DaemonProvider>
      </UploadProvider>
    </>
  );
}

export default withRoot(withStyles(styles)(withRouter(withWidth()(Index))));
