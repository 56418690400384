import { Suspense } from 'react';

function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={''}>
      <Component {...props} />
    </Suspense>
  );
}

export default WaitingComponent;
